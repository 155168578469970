import { resolvePACORemote } from './utilities/remoteResolver';

window.MfRemote = resolvePACORemote()
/**
 * the entry point mainly serve agent ONLY
 */
import('./eager-loader')
  .then((d) => d.default)
  .then((mod) => {
    return import('./setup-app').then(async ({ setup }) => {
      const { APP_TARGE, STORAGE_KEY } = await import('./constants');
      window.localStorage.setItem(STORAGE_KEY, APP_TARGE.AGENTS);
      return setup(mod);
    });
  });
